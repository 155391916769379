// utils
import '@styles/utilities/animations.scss';
import '@styles/utilities/shading.scss';
import '@styles/utilities/spacing.scss';
import '@styles/utilities/typography.scss';
import '@styles/utilities/alignment.scss';
import '@styles/utilities/tooltip.scss';
// reset
import '@styles/reset/layout.scss';
import '@styles/reset/typography.scss';

// class is removed from body on all pages so can't dynamic import styles
import '@styles/pages/p-preload.scss';

import buttons from '@modules/buttons';
import stickyNav from '@modules/stickyNav';
import modalNotification from '@modules/modalNotification';
import toast from '@modules/toast';
import socialIcons from '@modules/socialIcons';
import vimeoDntCookieBot from '@modules/vimeoDntCookieBot';
import formieCached from '@modules/formieCached';
import formie from '@modules/formie';
import plausibleSubmission from '@modules/plausibleSubmission';
import linkedInSubmission from '@modules/linkedInSubmission';
import gtmSubmission from '@modules/gtmSubmission';
import chevronCarousel from '@modules/chevronCarousel';
import videoControls from '@modules/videoControls';
import projectGrid from '@modules/projectGrid';
import projectCard from '@modules/projectCard';
import projectFilter from '@modules/projectFilter';
import projectHeroCarousel from '@modules/projectHeroCarousel';
import main from '@commons/main.js'
import SwiperCarousel from '@modules/swiperCarousel';
import glightbox from '@modules/glightbox';
import externalUrls from '@modules/externalLinkUrls';
import campaignTracker from '@modules/campaignTracker';
import customTooltip from '@modules/customTooltip';


// Dynamic Import Styles

// Dynamic import Elements
if(document.querySelector('.e-button')) {
  import('@styles/elements/e-button.scss');
}
if(document.querySelector('.e-eyebrow-heading')) {
  import('@styles/elements/e-eyebrow-heading.scss');
}
// if e-heading or one of the modifiers is present (as the modifiers are not used correctly in twig)
if(document.querySelector('.e-heading') || document.querySelector('.e-heading--title') || document.querySelector('.e-heading--primary') || document.querySelector('.e-heading--larger') || document.querySelector('.e-heading--large') || document.querySelector('.e-heading--medium') || document.querySelector('.e-heading--base') || document.querySelector('.e-heading--minor')) {
  import('@styles/elements/e-heading.scss');
}
if(document.querySelector('.e-social-list')) {
  import('@styles/elements/e-social-list.scss');
}
// if e-text or one of the modifiers is present (as the modifiers are not used correctly in twig)
if(document.querySelector('.e-text') || document.querySelector('.e-text--small-caps') || document.querySelector('.e-text--small-caps-bold') || document.querySelector('.e-text--minor') || document.querySelector('.e-text--major') || document.querySelector('.e-text--text-white') || document.querySelector('.e-text--text-deep-blue') || document.querySelector('.e-text--quote') || document.querySelector('.e-text--center') || document.querySelector('.e-text--peach')) {
  import('@styles/elements/e-text.scss');
}
if(document.querySelector('.e-video-btn')) {
  import('@styles/elements/e-video-btn.scss');
}
if(document.querySelector('.e-chevron-arrow')) {
  import('@styles/elements/e-chevron-arrow.scss');
}
if(document.querySelector('.e-overlay-image')) {
  import('@styles/elements/e-overlay-image.scss');
}
if(document.querySelector('.e-overlay-line')) {
  import('@styles/elements/e-overlay-line.scss');
}
if(document.querySelector('.e-overlay-thin-line')) {
  import('@styles/elements/e-overlay-thin-line.scss');
}
if(document.querySelector('.e-related-content')) {
  import('@styles/elements/e-related-content.scss');
}
if(document.querySelector('.e-link')) {
  import('@styles/elements/e-link.scss');
}
if(document.querySelector('.e-shuffle')) {
  import('@styles/elements/e-shuffle.scss');
}
if(document.querySelector('.e-author')) {
  import('@styles/elements/e-author.scss');
}
if(document.querySelector('.e-shard-image')) {
  import('@styles/elements/e-shard-image.scss');
}
if (document.querySelector('.e-carousel-controls')) {
  import('@styles/elements/e-carousel-controls.scss');
}
if (document.querySelector('.e-tiny-head')) {
  import('@styles/elements/e-tiny-head.scss');
}
if (document.querySelector('.e-inline-list')) {
  import('@styles/elements/e-inline-list.scss');
}
if (document.querySelector('.e-full-width-image')) {
  import('@styles/elements/e-full-width-image.scss');
}
if (document.querySelector('.e-timeline-eyebrow')) {
  import('@styles/elements/e-timeline-eyebrow.scss');
}


// Dynamic import mix of BEM types
if (document.querySelector('.b-contact-details')) {
  import('@styles/blocks/b-contact-details.scss');
  import('@styles/blocks/b-contact-form.scss');
  import('@styles/blocks/b-contact-carousel.scss');
  import('@styles/layouts/l-scrapbook-carousel.scss');
}
if (document.querySelector('.l-featured-blogs')) {
  import('@styles/layouts/l-featured-blogs.scss');
  import('@styles/blocks/b-featured-blog.scss');
}
if (document.querySelector('.b-service-summary')) {
  import('@styles/blocks/b-service-summary.scss');
  import('@styles/layouts/l-service-summary.scss');
}
if (document.querySelector('.b-stats')) {
  import('@styles/elements/e-stat.scss');
  import('@styles/blocks/b-stats.scss');
}
if (document.querySelector('.l-devices')) {
  import('@styles/layouts/l-devices.scss');
  import('@styles/blocks/b-device.scss');
}
if (document.querySelector('.l-scrapbook-grid')) {
  import('@styles/layouts/l-scrapbook-grid.scss');
  import('@styles/blocks/b-scrapbook-card.scss');
}


// Dynamic import layouts
if (document.querySelector('.l-featured-team')) {
  import('@styles/layouts/l-featured-team.scss');
}
if(document.querySelector('.l-2-col-aside')) {
  import('@styles/layouts/l-2-col-aside.scss');
}
if(document.querySelector('.l-card-grid')) {
  import('@styles/layouts/l-card-grid.scss');
}
if(document.querySelector('.l-project-carousel') || document.querySelector('.b-project-grid-card')) {
  import('@styles/layouts/l-project-carousel.scss');
}
if(document.querySelector('.l-fifty')) {
  import('@styles/layouts/l-fifty.scss');
}
if (document.querySelector('.l-project-intro')) {
  import('@styles/layouts/l-project-intro.scss');
}
if (document.querySelector('.l-card-project-grid')) {
  import('@styles/layouts/l-card-project-grid.scss');
}
if (document.querySelector('.l-content-container')) {
  import('@styles/layouts/l-content-container.scss');
}
if (document.querySelector('.l-full-width-image')) {
  import('@styles/layouts/l-full-width-image.scss');
}


// Dynamic import blocks
if (document.querySelector('.b-anniversary-story')) {
  import('@styles/blocks/b-anniversary-story.scss');
}
if (document.querySelector('.b-service-banner')) {
  import('@styles/blocks/b-service-banner.scss');
}
if (document.querySelector('.b-banner')) {
  import('@styles/blocks/b-banner.scss');
}
if (document.querySelector('.b-home-banner')) {
  import('@styles/blocks/b-home-banner.scss');
}
if (document.querySelector('.b-blogswheader')) {
  import('@styles/blocks/b-blogswheader.scss');
}
if (document.querySelector('.p-team')) {
  import('@styles/pages/p-team.scss');
}
if (document.querySelector('.b-values')) {
  import('@styles/blocks/b-values.scss');
}
if (document.querySelector('.b-what-we-do')) {
  import('@styles/blocks/b-what-we-do.scss');
}
if (document.querySelector('.b-tiny-heads')) {
  import('@styles/blocks/b-tiny-heads.scss');
}
if (document.querySelector('.b-service-listing')) {
  import('@styles/blocks/b-service-listing.scss');
}
if (document.querySelector('.b-related-services')) {
  import('@styles/blocks/b-related-services.scss');
}
if (document.querySelector('.b-project-listing')) {
  import('@styles/blocks/b-project-listing.scss');
}
if (document.querySelector('.b-native-video')) {
  import('@styles/blocks/b-native-video.scss');
}
if (document.querySelector('.b-media-two-up')) {
  import('@styles/blocks/b-media-two-up.scss');
}
if (document.querySelector('.l-team-grid')) {
  import('@styles/layouts/l-team-grid.scss');
}
if (document.querySelector('.b-carousel-card')) {
  import('@styles/blocks/b-carousel-card.scss');
}
if (document.querySelector('.b-project-banner')) {
  import('@styles/blocks/b-project-banner.scss');
}
if (document.querySelector('.b-project-card')) {
  import('@styles/blocks/b-project-card.scss');
}
if (document.querySelector('.b-project-filter')) {
  import('@styles/blocks/b-project-filter.scss');
}
if (document.querySelector('.b-project')) {
  import('@styles/blocks/b-project.scss');
}
if (document.querySelector('.b-job')) {
  import('@styles/blocks/b-job.scss');
}
if (document.querySelector('.b-collage')) {
  import('@styles/blocks/b-collage-card.scss');
}
if (document.querySelector('.b-method')) {
  import('@styles/blocks/b-method.scss');
}
if (document.querySelector('.b-landing-banner') || document.querySelector('.b-landing-banner__icons')) {
  import('@styles/blocks/b-landing-banner.scss');
}
if (
  document.querySelector('.b-landing-content')
  || document.querySelector('.b-landing-awards-block')
  || document.querySelector('.b-landing-three-icons')
  || document.querySelector('.b-landing-four-icons')
  || document.querySelector('.b-icon-headings')
) {
  import('@styles/blocks/b-landing-content.scss');
}
if (document.querySelector('.b-landing-testimonial')) {
  import('@styles/blocks/b-landing-testimonial.scss');
}
if (document.querySelector('.b-team-member')) {
  import('@styles/blocks/b-team-member.scss');
}
if (document.querySelector('.b-section-header')) {
  import('@styles/blocks/b-section-header.scss');
}
if (document.querySelector('.b-info-panel')) {
  import('@styles/blocks/b-info-panel.scss');
}
if (document.querySelector('.b-testimonial')) {
  import('@styles/blocks/b-testimonial.scss');
}
if (document.querySelector('.b-text-image-block')) {
  import('@styles/blocks/b-text-image-block.scss');
}
if (document.querySelector('.b-condensed-testimonial')) {
  import('@styles/blocks/b-condensed-testimonial.scss');
}
if (document.querySelector('.b-formie')) {
  import('@styles/blocks/b-formie.scss');
}
if(document.querySelector('.b-aside-nav')) {
  import('@styles/blocks/b-aside-nav.scss');
}
if(document.querySelector('.b-blog-card')) {
  import('@styles/blocks/b-blog-card.scss');
}
if(document.querySelector('.b-blog-listing')) {
  import('@styles/blocks/b-blog-listing.scss');
}
if(document.querySelector('.b-newsletter')) {
  import('@styles/blocks/b-newsletter.scss');
}
if(document.querySelector('.b-section-end-cta')) {
  import('@styles/blocks/b-section-end-cta.scss');
}
if(document.querySelector('.b-wysiwyg')) {
  import('@styles/blocks/b-wysiwyg.scss');
}
if(document.querySelector('.b-pagination')) {
  import('@styles/blocks/b-pagination.scss');
}
if(document.querySelector('.b-person-card')) {
  import('@styles/blocks/b-person-card.scss');
}
if(document.querySelector('.b-social-cta')) {
  import('@styles/blocks/b-social-cta.scss');
}
if(document.querySelector('.b-breakout-copy')) {
  import('@styles/blocks/b-breakout-copy.scss');
}
if(document.querySelector('.b-video')) {
  import('@styles/blocks/b-video.scss');
}
if(document.querySelector('.b-accreditations')) {
  import('@styles/blocks/b-accreditations.scss');
}
if(document.querySelector('.b-site-footer')) {
  import('@styles/blocks/b-site-footer.scss');
}
if(document.querySelector('.b-site-header')) {
  import('@styles/blocks/b-site-header.scss');
}
if(document.querySelector('.b-modal-notification')) {
  import('@styles/blocks/b-modal-notification.scss');
}
if(document.querySelector('.b-toast')) {
  import('@styles/blocks/b-toast.scss');
}
if(document.querySelector('.b-spinner')) {
  import('@styles/blocks/b-spinner.scss');
}

document.body.classList.remove('p-preload');
buttons();
stickyNav();
toast();
modalNotification();
socialIcons();
vimeoDntCookieBot();
formieCached();
formie();
plausibleSubmission();
linkedInSubmission();
gtmSubmission();
chevronCarousel();
videoControls();
projectGrid();
main();
projectCard();
projectFilter();
projectHeroCarousel();
SwiperCarousel();
glightbox();
externalUrls();
campaignTracker();
customTooltip();
if(document.querySelector(".js-team-grid")) {
import('@modules/shuffleTeam')
  .then((module) => {
    module.default();
  });
}
