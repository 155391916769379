import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

/**
 * Returns the given URL with any query string or fragment removed
 */
const cleanUrl = (url) => {
  const urlObj = new URL(url);
  return urlObj.origin + urlObj.pathname;
};

export default function init() {
  // Get all links
  const allLinks = document.querySelectorAll('a[href^="http"], a[href^="//"]');

  const allowedDomains = [
    "madebyextreme.com",
    "madebyextreme.com.ddev.site",
    "vimeo.com",
    "sable-dunnart.transforms.svdcdn.com",
  ];

  // Filter for external links, excluding specific domains
  const externalLinks = Array.from(allLinks).filter((link) => {
    try {
      const href = link.getAttribute("href");
      const hostname = new URL(href, window.location.origin).hostname;
      return !allowedDomains.includes(hostname) &&
      !hostname.includes('svdcdn.com');
    } catch (e) {
      // If URL parsing fails, assume it's not an external link
      return false;
    }
  });

  // Initialize tippy on each external link
  externalLinks.forEach((link) => {
    const href = link.getAttribute("href");
    tippy(link, {
      content: cleanUrl(href),
      theme: "extreme-custom",
      placement: "top",
      animation: "scale",
      arrow: true,
      offset: [0, 20],
    });
  });
}
